import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SaveButton from "component/common/saveButton";
import { offerLetterPost, resetEditOfferLetter } from "reduxToolkit/slices/JobSlice";
import { useDispatch } from "react-redux";
import ToastServices from "ToastServices";
import { awsURL } from "utils/Constants";
import { appendDataToFormData } from "redux/selector/Admin/jobOpening";


const PdfPreview = React.memo(
  ({
    setPreview,
    fileData,
    values,
    jobDetails,
    formData,
    inputText,
    isSubmiting,
  }) => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    const [generateLetter, setGenerateLetter] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const { t } = useTranslation();
    let currentDate = `${day}/${month}/${year}`;
    const pageRefs = useRef([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      if (fileData) {
        const objectUrl = URL.createObjectURL(fileData);
        setImageUrl(objectUrl);
        return () => {
          URL.revokeObjectURL(objectUrl);
        };
      }
    }, [fileData]);

    const onSubmit = async () => {
      try {
        if (!values || !jobDetails || !formData) {
          ToastServices.showToast({
            message: "Missing required data for offer letter",
            type: "error",
            autoClose: 3000,
          });
          return;
        }

        const {
          letterType,
          ctc,
          employeeType,
          deductions,
          onBoardingDate,
          noticePeriod,
          probationPeriod,
          preLocation,
          description,
        } = values;

        const pdfFormData = new FormData();

        const formatData = {
          letterType: letterType === "Offer Letter" ? letterType : inputText,
          jobTitle: jobDetails?.jobName,
          ctc,
          employeeType,
          deduction: deductions,
          onboardingDate: onBoardingDate,
          noticePeriod,
          probationTimePeriod: probationPeriod,
          location: preLocation,
          description,
          offeredById: formData?.id,
          signature: fileData,
        };

        appendDataToFormData(formatData, pdfFormData);
        pdfFormData.append("id", jobDetails?.id);
        dispatch(offerLetterPost(pdfFormData));
      } catch (error) {
        ToastServices.showToast({
          message: error.message || "An unexpected error occurred",
          type: "error",
          autoClose: 3000,
        });
      }
    };
    const handleSubmit = () => {
      onSubmit();
    }
    const splitContentIntoPages = useCallback(
      (content, isMainContent = false) => {
        if (!content) return [];
        const testDiv = document.createElement("div");
        testDiv.style.width = "794px";
        testDiv.style.padding = "56px 44px 80px";
        testDiv.style.position = "absolute";
        testDiv.style.visibility = "hidden";
        testDiv.style.whiteSpace = "pre-wrap";
        testDiv.style.wordBreak = "normal";
        testDiv.style.overflowWrap = "break-word";
        document.body.appendChild(testDiv);

        const headerHeight = isMainContent ? 70 : 0;
        const maxHeight = isMainContent ? 700 : 600;
        const firstPageMaxHeight = maxHeight - headerHeight;
        const compensationHeight = 400;

        const wrapContent = (text) => {
          return `<div style="white-space: pre-wrap; word-break: normal; overflow-wrap: break-word; max-width: 100%;">${text}</div>`;
        };

        const words = content.split(/(\s+)/);
        const pages = [];
        let currentPage = "";
        let isFirstPage = isMainContent;

        for (let i = 0; i < words.length; i++) {
          const word = words[i];
          let testContent = "";

          if (isMainContent && isFirstPage) {
            testContent = `
                <div style="height: ${headerHeight}px;">
                    <div class="flex justify-end mb-2">
                        <p class="text-right font-semibold">Date: ${t("currentDate")}</p>
                    </div>
                    <p>
                        To,<br/>
                        <h1 class="font-semibold capitalize">${jobDetails?.jobFullName}</h1>
                    </p>
                    <p class="mt-4 font-semibold capitalize">
                        ${t("subject")}
                        ${values?.letterType === "Other" ? inputText : values?.letterType}
                        ${t("forThePost")} ${jobDetails?.appliedFor}
                    </p>
                    <p class="mt-4 capitalize">{Dear} ${jobDetails?.jobFullName},</p>
                </div>#
                ${wrapContent(currentPage + word)}`;
          } else {
            testContent = wrapContent(currentPage + word);
          }

          testDiv.innerHTML = testContent;
          const currentHeight = testDiv.offsetHeight;

          const remainingContent = words.slice(i).join('');
          testDiv.innerHTML = wrapContent(remainingContent);
          const remainingHeight = testDiv.offsetHeight;
          const couldBeLastPage = remainingHeight + currentHeight + compensationHeight <= maxHeight;

          const currentMaxHeight = isFirstPage ? firstPageMaxHeight :
            couldBeLastPage ? maxHeight - compensationHeight :
              maxHeight;

          if (currentHeight > currentMaxHeight) {
            const lastSpaceIndex = currentPage.lastIndexOf(" ");
            if (lastSpaceIndex !== -1) {
              pages.push(currentPage.substring(0, lastSpaceIndex).trim());
              currentPage = currentPage.substring(lastSpaceIndex + 1) + word;
            } else {
              pages.push(currentPage.trim());
              currentPage = word;
            }
            if (isFirstPage) {
              isFirstPage = false;
            }
          } else {
            currentPage += word;
          }
        }

        if (currentPage.trim().length > 0) {
          pages.push(currentPage.trim());
        }

        document.body.removeChild(testDiv);
        return pages;
      },
      [currentDate, jobDetails, values, inputText, t]
    );

    const renderPageTemplate = useCallback(
      (content, index, isFirstPage, isLastIndex) => (
        <div
          ref={(el) => (pageRefs.current[index] = el)}
          className="border border-gray-300 bg-opacity-90 rounded-lg shadow-md flex flex-col first:mt-0 mb-8 bg-cover"
          style={{
            height: "862px",
            width: "full",
            backgroundColor: "white",
            position: "relative",
            backgroundImage: `url('${awsURL}/images/deeporion-letter-head.png')`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className={`z-10 px-10 pt-11 ${isLastIndex ? 'pb-0' : 'pb-12'} h-full flex flex-col`}>
            <div className={isLastIndex ? 'max-h-[55%]' : ''}>
              {isFirstPage && (
                <>
                  <div className="flex justify-end mt-[5rem]">
                    <p className="text-right font-semibold">{t("date")}: {currentDate}</p>
                  </div>
                  <p>
                    To,
                    <br />
                    <h1 className="font-semibold capitalize">{jobDetails?.jobFullName}</h1>
                  </p>
                  <p className="mt-4 font-semibold capitalize">
                    {t("subject")}{" "}
                    {values?.letterType === "Other" ? inputText : values?.letterType}{" "}
                    {t("forThePost")} {jobDetails?.appliedFor}
                  </p>
                  <p className="mt-4 capitalize">{t("Dear")} {jobDetails?.jobFullName},</p>
                </>
              )}
              <div
                className={`${isFirstPage ? "mt-8" : "mt-14"}`}
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "normal",
                  overflowWrap: "break-word",
                  maxWidth: "100%",
                }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>

            {isLastIndex && (
              <div className="z-10 mt-auto pt-8">
                <h3 className="font-semibold text-lg mb-9 underline decoration-3">
                  {t("compensationDetails")}
                </h3>
                <h3 className="font-semibold text-lg mb-2">{t("salary")}</h3>
                <table className="table-auto border-3 border-black w-full">
                  <tbody>
                    <tr>
                      <td className="border-2 border-black px-4 py-2 font-semibold">{t("ctc")}</td>
                      <td className="border-2 border-black px-4 py-2">{values?.ctc}</td>
                    </tr>
                    <tr>
                      <td className="border-2 border-black px-4 py-2 font-semibold">{t("deduction")}</td>
                      <td className="border-2 border-black px-4 py-2">{t("taxDeduction")}</td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <p className="mt-3" >{t("yoursSincerely")}</p>
                  <div className="text-left object-fill">
                    {imageUrl ? (
                      <img src={imageUrl} alt="Signature" className="h-16 w-30" />
                    ) : (
                      <p className="text-[red] font-light">{t("uplpoadSignature")}</p>
                    )}
                  </div>
                  <p className="mt-1 font-semibold capitalize">
                    {formData?.name || (
                      <span className="text-[red] font-light">{t("assignedName")}</span>
                    )}
                    <br />
                    {formData?.designation}
                  </p>
                </div>

              </div>
            )}
          </div>
        </div>
      ),
      [currentDate, jobDetails, values, inputText, t, imageUrl, formData]
    );

    const handleDownloadPDF = useCallback(async () => {
      setIsGenerating(true);
      const pdf = new jsPDF("p", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      try {
        const backgroundImage = await fetch(
          `url('${awsURL}/images/deeporion-letter-head.png')`
        )
          .then((response) => response.blob())
          .then((blob) => {
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            });
          });

        for (let i = 0; i < pageRefs.current.length; i++) {
          const page = pageRefs.current[i];
          if (!page) continue;

          if (i !== 0) {
            pdf.addPage();
          }
          pdf.addImage(backgroundImage, "PNG", 0, 0, pdfWidth, pdfHeight);
          const contentCanvas = await html2canvas(page, {
            scale: 2,
            useCORS: true,
            allowTaint: true,
            backgroundColor: null,
            width: page.offsetWidth,
            height: page.offsetHeight,
            ignoreElements: (element) => {
              return element.classList.contains("bg-image-container");
            },
          });
          const contentImage = contentCanvas.toDataURL("image/png", 1.0);
          pdf.addImage(
            contentImage,
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight,
            undefined,
            "FAST"
          );
        }

        pdf.save("letter.pdf");
      } catch (error) {
        alert("There was an error generating the PDF. Please try again.");
      } finally {
        setIsGenerating(false);
      }
    }, []);

    const renderAllPages = useMemo(() => {
      const allPages = [];
      let pageIndex = 0;

      const combinedContent = values.termsCondition
        ? `${values.description}\n
         <h1>Terms and conditions
         </h1>
        ${values.termsCondition}`
        : values.description ? values.description : "";

      const allContentPages = splitContentIntoPages(combinedContent, true);

      allContentPages.forEach((pageContent, index) => {
        allPages.push(
          renderPageTemplate(
            pageContent,
            pageIndex++,
            index === 0,
            index === allContentPages.length - 1
          )
        );
      });

      pageRefs.current = pageRefs.current.slice(0, pageIndex);
      return allPages;
    }, [
      values.description,
      values.termsCondition,
      splitContentIntoPages,
      renderPageTemplate,
    ]);
    return (
      <div className="flex flex-col z-40">
        <div className="pdf-preview-content w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
          <div className="h-full bg-white flex flex-col shadow-lg w-[50rem] transition-all">
            <div className="h-full">
              <div className="w-full p-5 bg-white flex justify-between border-b">
                <div className="flex items-center font-extrabold gap-5 w-full justify-between">
                  <div className="flex items-center gap-3 w-full">
                    <div
                      className="cursor-pointer"
                      onClick={() => setPreview(false)}
                    >
                      <FaChevronLeft />
                    </div>
                    <h2 className="font-extrabold text-xl text-[#031B59]">
                      {t("preview")}
                    </h2>
                  </div>
                  <button
                    onClick={handleDownloadPDF}
                    disabled={isGenerating}
                    className={`${isGenerating
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700"
                      } text-white px-4 py-2 rounded-md transition-colors z-40`}
                  >
                    {isGenerating ? t("generating") : t("generatePDF")}
                  </button>
                  <SaveButton isLoading={false} saveText={t("save")} onClick={handleSubmit} onGenerateLetter={setGenerateLetter} disabled={isSubmiting}></SaveButton>
                </div>
              </div>
              <div className="h-[91vh] overflow-y-auto px-8 py-4 italic break-all w-[782px]">
                {renderAllPages}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PdfPreview;