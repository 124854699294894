import Search from 'component/common/accessControlUi/Search';
import LoaderComp from 'component/loader/LoaderComp';
import usePermissions, { mappedPermissionObj } from 'hooks/usePermission';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineLineChart } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardJobTitle } from 'reduxToolkit/slices/JobSlice';
import { setLoading } from 'reduxToolkit/slices/calender';

const DashboardJobTitle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allJobTitle } = useSelector((state) => state.jobs);
  const localData = JSON.parse(localStorage.getItem('userLoginToken')) || {};
  const [searchQuery, setSearchQuery] = useState("");
  const { userPermission } = usePermissions(mappedPermissionObj.Jobopening);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (localData?.token) {
      dispatch(dashboardJobTitle(searchQuery, setLoading))
    }
  }, [searchQuery, setLoading]);
  return (
    <div className='h-fit flex flex-col gap-3'>
      <div>
        <div className='flex justify-between py-2 items-center border-b-2'>
          <div className='flex gap-6 items-center'>
            <h1 className='font-semibold text-[1rem] text-[#031B59]'>{t('jobTitle')}</h1>
            <div
              className="">
              <Search
                searchItem={searchQuery}
                setSearchItem={setSearchQuery}
                currentResource={userPermission}
              />
            </div>
          </div>
          <h1 className='text-[#1B4FB6]'>{t('applicationForJobTitle')}</h1>
        </div>
      </div>
      <div className='h-[25vh] overflow-y-auto custom_scroll flex flex-col gap-4'>
        {loading ? (<div className='w-full flex justify-center'>
          <LoaderComp />
        </div>) : (
          <>
            {allJobTitle.length > 0 ? (
              allJobTitle?.map((item, id) => {
                return (
                  <div key={id} className='w-full flex items-center justify-between'>
                    <h1 className='font-medium text-[1.1rem] capitalize'>{item?.jobTitle}</h1>
                    <div className='flex items-center gap-2'>
                      <p>{item?.applicationCount}</p>
                      <div className='text-[2rem] text-[#ED6E0F] border-2 w-fit rounded-[0.25rem]'>
                        <AiOutlineLineChart />
                      </div>
                    </div>
                  </div>
                )
              })) : (
              <div className='flex justify-center'>
                <h1 className='text-[1.1rem] capitalize'>
                  {t("no_data_found")}
                </h1>
              </div>
            )
            }
          </>
        )
        }
      </div>
    </div>
  )
}
export default DashboardJobTitle