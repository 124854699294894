import DatePicker from "component/common/DatePicker";
import RightOpenPop from "component/common/RightOpenPop";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { optionLocation } from "utils/Constants";
import PdfPreview from "./PdfPreview";
import { offerLetterValidation } from "redux/validator/admin/jobOpening";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import { handleSearchUserForInterView } from "redux/appThunk/Employee/chat";
import { GrFormClose } from "react-icons/gr";
import { RxCross1 } from "react-icons/rx";
import { BiCloudUpload } from "react-icons/bi";
import { resetEditOfferLetter } from "reduxToolkit/slices/JobSlice";

const GenerateLetter = ({ setGenerateLetter, jobDetails, setAgainData }) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState(false);
  const [urlInput, setUrlInput] = useState(false);
  const [loder, setLoder] = useState(false);
  const { searchResult, editOfferLetter, offerLetterData } = useSelector((state) => state.jobs);
  const [formData, setFormData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [inputText, setInputText] = useState("");
  const [guestName, setGuestName] = useState("");
  let searchQuery = useDebounce(guestName, 300);
  const dispatch = useDispatch();
  const [wordCount, setWordCount] = useState(0);
  const maxWords = 190;

  const initialValues = {
    letterType: editOfferLetter ? offerLetterData?.letterType : "",
    ctc: editOfferLetter ? offerLetterData?.ctc : "",
    employeeType: "",
    deductions: t("taxDeduction"),
    onBoardingDate: editOfferLetter ? offerLetterData?.onboardingDate : "",
    noticePeriod: editOfferLetter ? offerLetterData?.noticePeriod : "",
    probationPeriod: "",
    preLocation: editOfferLetter ? offerLetterData?.location : "",
    offeredBy: editOfferLetter && offerLetterData ? offerLetterData?.offeredBy : "",
    signatureBy: editOfferLetter ? offerLetterData?.signatureBy : "",
    description: editOfferLetter ? offerLetterData?.description : "",
    timeEmployment: t("timelineText"),
    note: "You will receive a salary, and all other benefits forming part of your remuneration package subject to, and after, deduction of tax at source in accordance with applicable law.",
    termsCondition: "",
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic"],
      ["link"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { list: "check" },
        { script: "sub" },
        { script: "super" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["clean"],
      ["blockquote", "image"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const handlePreview = () => {
    setPreview(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileData(file);
    }
  };

  const handleLetterTypeChange = (e) => {
    const selectedLetterType = e.target.value;
    handleChange(e);
    if (selectedLetterType === "Offer Letter") {
      setUrlInput(false);
      setFieldValue("letterType", "Offer Letter");
    } else if (selectedLetterType === "Other") {
      setUrlInput(true);
      setFieldValue("letterType", "Other");
    } else {
      setUrlInput(false);
      setFieldValue("letterType", "");
    }
  };

  const handleInputTextChange = (e) => {
    setInputText(e.target.value);
  };

  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: offerLetterValidation,
    onSubmit: async (values, { setSubmitting }) => {

      setPreview(true);
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    errors,
    touched,
  } = Formik;

  useEffect(() => {
    return () => {
      dispatch(resetEditOfferLetter());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!editOfferLetter) {
      setFieldValue("letterType", "");
      setFieldValue("ctc", "");
      setFieldValue("onBoardingDate", "");
      setFieldValue("noticePeriod", "");
      setFieldValue("preLocation", "");
      setFieldValue("description", "");
    }
  }, [editOfferLetter, setFieldValue]);


  const selectEmployee = (employee) => {
    setFormData(employee);
    setGuestName("");
  };

  useEffect(() => {
    if (guestName !== "") {
      dispatch(handleSearchUserForInterView(searchQuery, () => { }));
    }
  }, [searchQuery, dispatch]);

  const removeGuest = () => {
    setFormData(null);
  };

  const handleDescQuillChange = (content, delta, source, editor) => {
    // const currentText = editor.getText();
    // const currentWordCount = currentText.split(/\s+/).filter(Boolean).length;
    // if (currentWordCount <= maxWords && currentText.length <= maxCharacters) {
    //   setFieldValue("description", content);
    //   setWordCount(currentWordCount);
    // }
    setFieldValue("description", content);
    // setWordCount(currentWordCount);
  };
  const handleTermsQuillChange = (content, delta, source, editor) => {
    // const currentText = editor.getText();
    // const currentWordCount = currentText.split(/\s+/).filter(Boolean).length;
    // if (currentWordCount <= maxWords && currentText.length <= maxCharacters) {

    //   setFieldValue("termsCondition", content);
    //   setWordCount(currentWordCount);
    // }
    setFieldValue("termsCondition", content);
  };

  return (
    <>
      <RightOpenPop
        cancelText={t("Preview")}
        saveText={t("save")}
        onClose={setGenerateLetter}
        previewIcon={true}
        handlePreview={handleSubmit}
        handleSubmit={handleSubmit}
        loder={loder}
      >
        <>
          {preview && (
            <PdfPreview
              setPreview={setPreview}
              fileData={fileData}
              values={values}
              jobDetails={jobDetails}
              formData={formData}
              inputText={inputText}
              preview={preview}
            />
          )}
          <div className="h-full max-h-[83vh] overflow-y-auto custom_scroll">
            <div className="px-5 py-2">
              <div className="flex flex-col gap-4">
                <h2 className="font-semibold text-[1rem]">
                  {t("ContactInfo")}
                </h2>
                <div className="grid xl:grid-cols-2 gap-3">
                  <div>
                    <label className="text-[#686868] text-[0.9rem]">
                      {t("candidateName")}
                    </label>
                    <h2 className="font-medium text-[1.1rem] capitalize">
                      {jobDetails?.jobFullName}
                    </h2>
                  </div>
                  <div>
                    <label className="text-[#686868] text-[0.9rem]">
                      {t("email")}
                    </label>
                    <h2 className="font-medium text-[1.1rem] capitalize">
                      {jobDetails?.email}
                    </h2>
                  </div>
                  <div>
                    <label className="text-[#686868] text-[0.9rem]">
                      {t("state")}
                    </label>
                    <h2 className="font-medium text-[1.1rem] capitalize">
                      {jobDetails?.state}
                    </h2>
                  </div>
                  <div>
                    <label className="text-[#686868] text-[0.9rem]">
                      {t("country")}
                    </label>
                    <h2 className="font-medium text-[1.1rem] capitalize">
                      {jobDetails?.country}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-2" />
            <div className="px-5 py-2 h-full">
              <div className="flex flex-col gap-3">
                <h2 className="font-semibold text-[1rem]">{t("comBenifit")}</h2>
                <div className="flex flex-col gap-3">
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("jobTitle")}
                    </label>
                    <input
                      className="border w-full h-12 rounded-md p-2 capitalize"
                      value={jobDetails?.jobName}
                      disabled
                    />
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("letterType")}
                    </label>
                    <select
                      name="letterType"
                      value={values.letterType}
                      onChange={handleLetterTypeChange}
                      onBlur={handleBlur}
                      className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px] text-[#191919] bg-white cursor-pointer"
                    >
                      <option value="">{t("select")}</option>
                      <option value={t("offerLetter")}>
                        {t("offerLetter")}
                      </option>
                      <option value="Other">{t("Other")}</option>
                    </select>

                    {urlInput && (
                      <input
                        value={inputText}
                        onChange={handleInputTextChange}
                        className="w-full p-2 border mt-3"
                        type="text"
                        placeholder="Enter your custom letter type"
                      />
                    )}

                    {errors?.letterType && touched?.letterType && (
                      <p className="text-[red]">{errors.letterType}</p>
                    )}
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("onBoardingDate")}
                    </label>
                    <DatePicker
                      name="onBoardingDate"
                      min={new Date().toISOString().split("T")[0]}
                      className="h-[2.625rem] w-full"
                      value={values.onBoardingDate}
                      handleChange={handleChange}
                    />
                    {errors?.onBoardingDate && touched?.onBoardingDate && (
                      <p className="text-[red]">{errors.onBoardingDate}</p>
                    )}
                  </div>
                  <div className="flex flex-col space-y-2 relative">
                    <label
                      htmlFor="preLocation"
                      className="text-[#313135] font-semibold text-[1.2rem]"
                    >
                      {t("preLocation")}
                    </label>
                    <select
                      name="preLocation"
                      value={values.preLocation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="h-[2.625rem] w-full border rounded
                  p-[10px_14px_10px_10px] text-[#191919] bg-white"
                    >
                      <option value="">{t("select")}</option>
                      {optionLocation?.map((item) => {
                        return <option value={item.value}>{item.value}</option>;
                      })}
                    </select>
                    {errors?.preLocation && touched?.preLocation && (
                      <p className="text-[red]">{errors.preLocation}</p>
                    )}
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("description")}
                    </label>
                    <ReactQuill
                      className="w-full h-fit"
                      placeholder="Content"
                      style={{ color: "#000000", minHeight: "100px" }}
                      values={values.description}
                      modules={modules}
                      theme="snow"
                      value={values.description}
                      onChange={handleDescQuillChange}
                    />
                    {errors?.description && touched?.description && (
                      <p className="text-[red]">{errors.description}</p>
                    )}
                    <div className="text-right">
                      <span className="text-sm">
                        {wordCount} / {maxWords} words
                      </span>
                    </div>
                  </div>
                  <div className="relative">
                    <label className="font-semibold text-[1.2rem]">
                      {t("offeredBy")}
                    </label>
                    {formData ? (
                      <span
                        className="p-2 flex flex-row items-center justify-normal gap-2
                        rounded-full bg-[#F2F6FF] w-fit"
                      >
                        {formData.name}
                        <GrFormClose
                          className="fill-[#A1A1A1] w-5 h-5 p-1 box-content cursor-pointer"
                          onClick={removeGuest}
                        />
                      </span>
                    ) : (
                      <input
                        type="text"
                        name="offeredBy"
                        value={guestName}
                        onChange={(e) => setGuestName(e.target.value)}
                        onBlur={handleBlur}
                        className="border w-full h-12 rounded-md p-2"
                        placeholder="Search Employee ...."
                      />
                    )}
                    {/* </div>
                  </div> */}
                    {guestName !== "" && (
                      <div className="absolute flex justify-start flex-col w-full max-w-[36rem] bg-white gap-3 shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10 overflow-y-auto max-h-60 custom_scroll">
                        {searchResult.length > 0 ? (
                          searchResult.map((employee) => (
                            <div
                              key={employee.id}
                              onClick={() => selectEmployee(employee)}
                              className="flex items-center text-black h-[3rem] px-2 cursor-pointer hover:bg-gray-100"
                            >
                              <img
                                src={`https://ui-avatars.com/api/?name=${employee.name}&background=random`}
                                alt="Profile Avatar"
                                className="w-8 h-8 rounded-full mr-2"
                              />
                              <p className="break-all">{employee.name}</p>
                            </div>
                          ))
                        ) : (
                          <div>{t("noDataFound")}</div>
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("signatureBy")}
                    </label>
                    {fileData ? (
                      <div
                        className="p-2 flex flex-row items-center justify-normal gap-2
                        rounded-full bg-[#F2F6FF] w-fit"
                      >
                        <h1>{fileData?.name}</h1>
                        <RxCross1
                          className="cursor-pointer"
                          onClick={() => setFileData(null)}
                        />
                      </div>
                    ) : (
                      <>
                        <input
                          type="file"
                          id="file-upload"
                          name="signatureBy"
                          value={values.signatureBy}
                          onChange={handleFileChange}
                          onBlur={handleBlur}
                          className="hidden"
                        />
                        <label
                          htmlFor="file-upload"
                          className="text-[#031b59] font-semibold cursor-pointer flex items-center gap-4 border-2 rounded-lg border-dashed border-gray-200 p-2 w-[15vw]"
                        >
                          <BiCloudUpload className="text-[1.8rem] text-[grey]" />
                          {t("upload")}
                        </label>
                      </>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <label className="font-semibold text-[1.2rem]">
                      {t("timeEmployment")}
                    </label>
                    <textarea
                      type="text"
                      name="timeEmployment"
                      value={values?.timeEmployment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="border w-full rounded-md p-2"
                    />
                    {errors?.timeEmployment && touched?.timeEmployment && (
                      <p className="text-[red]">{errors.timeEmployment}</p>
                    )}
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("termsCondition")}
                    </label>
                    <ReactQuill
                      className="w-full h-fit"
                      placeholder="Content"
                      style={{ color: "#000000", minHeight: "100px" }}
                      modules={modules}
                      theme="snow"
                      value={values.termsCondition}
                      onChange={handleTermsQuillChange}
                    />
                  </div>

                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("ctcWithout")}
                    </label>
                    <input
                      type="text"
                      name="ctc"
                      value={values.ctc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="border w-full h-12 rounded-md p-2"
                    />
                    {errors?.ctc && touched?.ctc && (
                      <p className="text-[red]">{errors.ctc}</p>
                    )}
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("deductions")}
                    </label>
                    <input
                      type="text"
                      name="deductions"
                      value={values.deductions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="border w-full h-12 rounded-md p-2"
                    />
                    {errors?.deductions && touched?.deductions && (
                      <p className="text-[red]">{errors.deductions}</p>
                    )}
                  </div>
                  <div>
                    <label className="font-semibold text-[1.2rem]">
                      {t("note")}
                    </label>
                    <input
                      type="text"
                      name="note"
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="border w-full h-12 rounded-md p-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </RightOpenPop>
    </>
  );
};

export default GenerateLetter;
