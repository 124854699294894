import { configureStore } from "@reduxjs/toolkit";
import { jobReducer } from "reduxToolkit/slices/JobSlice";
import applicantReducer from "reduxToolkit/slices/applicantSlice";
import calenderReducer from "reduxToolkit/slices/calender"

const store = configureStore({
  reducer: {
    jobs: jobReducer,
    applicants: applicantReducer,
    calender: calenderReducer
  },
});

export default store;
