import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import Popup from "./Popup";
import { updateDropdownStatus } from "reduxToolkit/slices/JobSlice";

function OnHoldPopups({ setOnHoldPopup, setAgainData, job }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    onSubmit: (values) => {
      const jsonObj = {
        ...values, 
        ...job
        
      }
      dispatch(updateDropdownStatus(jsonObj, setAgainData));
      setOnHoldPopup(false);
    },
  });

  return (
    <Popup
      title={<span className="text-[#191919]">{t("moveOnhold")}</span>}
      popupBox={() => setOnHoldPopup(false)}
      submitBtnText={t("Move")}
      handleSubmit={formik.handleSubmit}
      maxWidth="max-w-[200px]"
      titleClass="pt-8"
    >
      <form onSubmit={formik.handleSubmit} className="p-4">
        <input
          name="reason"
          value={formik.values.reason}
          onChange={formik.handleChange}
          className="border p-3 w-full"
          placeholder="State a reason here"
        />
      </form>
    </Popup>
  );
}

OnHoldPopups.propTypes = {
  setOnHoldPopup: PropTypes.func.isRequired,
  setAgainData: PropTypes.func.isRequired,
  statusString: PropTypes.string.isRequired,
  rejectDetails: PropTypes.object.isRequired,
  job: PropTypes.object  
};

export default OnHoldPopups;
