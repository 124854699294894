import React, { useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import StarRating from "./StarRatings";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import LoaderComp from "component/loader/LoaderComp";
import { useTranslation } from "react-i18next";
import { AddFeedBack, UpdateFeedBack, getfeeback } from "reduxToolkit/slices/JobSlice";
import { MdOutlineAttachFile } from "react-icons/md";
import { GoSmiley } from "react-icons/go";
import EmojiPicker from "emoji-picker-react";
import { RxCross2 } from "react-icons/rx";

const WriteFeedBack = ({
  setAgainData,
  jobId,
  closeRightPopup,
  editData,
  setEditData,
  job,
}) => {
  const dispatch = useDispatch();
  const [loder, setLoder] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [files, setFiles] = useState([]);

  const fileInputId = "fileInputId";

  const [rating, setRating] = useState({
    "Technical Skills": editData ? editData?.technicalskills : 0,
    "Soft Skills": editData ? editData?.soft_skills : 0,
    "Experience and Knowledge": editData ? editData?.experienceandknowledge : 0,
    "Problem-Solving and Critical Thinking": editData
      ? editData?.problemsolvingandcriticalthinking
      : 0,
    "Project Management": editData ? editData?.projectmanagement : 0,
    "Cultural Fit": editData ? editData?.culturalfit : 0,
    "Performance Under Pressure": editData
      ? editData?.performanceunderpressure
      : 0,
    "Leadership and Initiative": editData
      ? editData?.leadershipandinitiative
      : 0,
  });
  const [description, setDescription] = useState(
    editData ? editData.description : ""
  );
  const { t } = useTranslation();
  const handleRating = (index, skill) => {
    setRating({ ...rating, [skill]: index });
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };
  const onEmojiClick = (emojiData) => {
    setDescription((prevdescription) => prevdescription + emojiData.emoji);
    setShowPicker(false);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const filesArray = Array.from(files);
      setFiles(filesArray);
    }
  };
  const handleIconClick = () => {
    const fileInputElement = document.getElementById(fileInputId);
    if (fileInputElement) {
      fileInputElement.click();
    }
  };
  const handleRemoveFile = (item) => {
    const findIndex = files.filter((image) => image.name != item.name);
    setFiles(findIndex);
  };
  const saveFeedBack = async () => {
    const newForm = new FormData();
    newForm.append(
      "job_application_feedbacks[leadership_and_initiative]",
      rating["Leadership and Initiative"]
    );
    newForm.append(
      "job_application_feedbacks[technical_skills]",
      rating["Technical Skills"]
    );
    newForm.append(
      "job_application_feedbacks[soft_skills]",
      rating["Soft Skills"]
    );
    newForm.append(
      "job_application_feedbacks[experience_and_knowledge]",
      rating["Experience and Knowledge"]
    );
    newForm.append(
      "job_application_feedbacks[problem_solving_and_critical_thinking]",
      rating["Problem-Solving and Critical Thinking"]
    );
    if (files.length > 0) {
      newForm.append("job_application_feedbacks[images][]", files[0]);
    }
    newForm.append("job_application_feedbacks[description]", description);
    newForm.append(
      "job_application_feedbacks[project_management]",
      rating["Project Management"]
    );
    newForm.append(
      "job_application_feedbacks[cultural_fit]",
      rating["Cultural Fit"]
    );
    newForm.append(
      "job_application_feedbacks[performance_under_pressure]",
      rating["Performance Under Pressure"]
    );
    newForm.append(
      "job_application_feedbacks[round]",
      editData ? editData?.round : job?.round ? job?.round : null
    );
    // },
    // };

    if (editData) {
      await dispatch(
        UpdateFeedBack(editData.id, newForm, setLoder, closeRightPopup)
      );
      await dispatch(getfeeback(jobId, setLoder));
      await setEditData(null);
    } else {
      await dispatch(
        AddFeedBack(setAgainData, jobId, newForm, setLoder, closeRightPopup)
      );
      await dispatch(getfeeback(jobId, setLoder));
    }
  };

  return (
    <div className="fixed top-0 right-0 h-full w-[40%] bg-white z-50 shadow-lg overflow-auto">
      <div className="relative w-full h-full p-5 flex flex-col justify-between">
        <div>
          <div className="w-full flex items-center justify-between">
            <button onClick={closeRightPopup} className="flex items-center">
              <BsChevronLeft className="h-3 w-4 stroke-1 cursor-pointer text-[#031B59]" />
              <span className="font-Roboto text-2xl text-[#031B59] font-extrabold ml-5">
                {t("writeFeed")}
              </span>
            </button>
          </div>
          <hr className="border-t border-gray-300 mt-4 w-full mb-4" />
          {job?.round ? (
            <div className="w-full bg-[#F2F6FF] flex items-center p-3 rounded-md">
              <button className="flex justify-between w-full items-center">
                <h1>{editData ? editData?.round : job?.round}</h1>
              </button>
            </div>
          ) : null}

          <h3 className="font-Roboto text-[1.4rem] font-bold mt-4 mb-4">
            {t("skillEvol")}
          </h3>
          <div className="font-Roboto text-lg text-[#686868] font-extrabold grid grid-cols-2 gap-4">
            {Object.keys(rating).map((skill, index) => (
              <div key={index} className="flex flex-col gap-2">
                <p className="font-Roboto text-lg text-[#686868] font-[400]">{`${t(
                  skill
                )}`}</p>
                <StarRating
                  handleRating={handleRating}
                  rating={rating[skill]}
                  totalStars={5}
                  skill={skill}
                />
              </div>
            ))}
          </div>
          <p className="font-Roboto text-md font-semibold text-[#A1A1A1] mt-5">
            {t("writeAddition")}
          </p>
          <div className="w-full flex justify-end">
            {showPicker && (
              <div className="absolute z-50  bottom-[20rem]">
                <EmojiPicker onEmojiClick={onEmojiClick} />
              </div>
            )}
          </div>
          <textarea
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md resize-none bg-[#F2F6FF]"
            rows="4"
            value={description}
          ></textarea>

          <div className="w-full flex justify-end items-center gap-6 p-2">
            <button className="h-fit w-fit">
              <GoSmiley
                data-testid="ShowPicker-btn"
                className="fill-[#A1A1A1] h-6 w-6"
                onClick={() => setShowPicker(!showPicker)}
              />
            </button>
            <button className="h-fit w-fit bg-[#F2F6FF] p-2 rounded-full">
              <input
                type="file"
                id={fileInputId}
                onChange={handleFileChange}
                style={{ display: "none" }}
                multiple
                accept="image/*"
              />
              <MdOutlineAttachFile
                className="-rotate-45 h-6 w-6 fill-[#A1A1A1]"
                data-testid="attach-file-icon"
                onClick={handleIconClick}
              />
            </button>
          </div>
          <div className="flex gap-[10px]">
            {files?.length > 0 &&
              files?.map((item) => {
                return (
                  <div
                    className="w-full flex gap-3 items-center h-16"
                    key={item?.dataImage}
                  >
                    <p className="leading-7 font-medium h-fit overflow-hidden">
                      {item?.name}
                    </p>
                    <RxCross2
                      color="black"
                      className=" text-[18px]"
                      onClick={() => handleRemoveFile(item)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div>
          <hr className="border-t border-gray-300 mt-4 w-full" />
          <div className="flex justify-end items-center gap-4 p-4">
            <button
              onClick={closeRightPopup}
              className="font-Roboto border px-5
               py-3 text-[#686868] font-extrabold text-lg rounded-md "
            >
              {t("cancel")}
            </button>
            {loder ? (
              <LoaderComp />
            ) : (
              <button
                className={`px-6 py-3 ${
                  Object.values(rating).every((value) => value === 0)
                    ? "bg-gray-400"
                    : "bg-[#031B59]"
                } text-white text-lg rounded-md flex items-center`}
                disabled={Object.values(rating).every((value) => value === 0)}
                onClick={saveFeedBack}
              >
                {t("save")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriteFeedBack;

WriteFeedBack.propTypes = {
  jobId: PropTypes.number,
  closeRightPopup: PropTypes?.boolean,
  editData: PropTypes.any,
  setEditData: PropTypes.any,
};
