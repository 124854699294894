import React from "react";


const SaveButton = ({ isLoading, saveText, onClick, onGenerateLetter }) => {

  const handleClick = () => {
    onClick();

    if (onGenerateLetter) {
      onGenerateLetter(true);
    }
  };
  return isLoading ? (
    <div
      className={`flex h-[3rem] p-[1rem_1.875rem] min-w-[6rem] justify-center items-center gap-[0.5rem]
      bg-[#031B59] rounded-[2.5rem] font-inter text-[1rem]
       font-bold leading-normal text-white`}
    >
      <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white"></div>
    </div>
  ) : (
    <button
      className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition-colors z-40"
      type="button"
      onClick={handleClick}
    >
      {saveText}
    </button>
  );
};

export default SaveButton;
