import ProfileAvtar from 'component/common/ProfileAvtar';
import { useOnClickOutside } from 'component/common/useOneClickOutsid';
import JobApplicantDetails from 'component/jobOpening/JobApplicantDetails';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dashboardNewApplicants } from 'reduxToolkit/slices/JobSlice';

function DashboardNewApplication(setAgainData) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allNewApplicants } = useSelector((state) => state.jobs);
  const localData = JSON.parse(localStorage.getItem('userLoginToken')) || {};
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [personal, setPersonal] = useState(null);
  const [details, setDetails] = useState(false);
  const menuRef = useRef();
  useEffect(() => {
    if (localData?.token) {
      dispatch(dashboardNewApplicants());
    }
  }, [dispatch]);

  const handleNavigate = () => {
    navigate("/opening/applicants");
  }
  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  }

  const handleViewDetails = (jobSelest) => {
    setPersonal(jobSelest);
    setDetails(true);
  };

  useOnClickOutside(menuRef, () => {
    setDropdownOpen(false);
  });

  const handleViewResume = (file) => {
    window.open(file?.resumeUrl?.url, "_blank");
  }

  return (
    <>
      {details && (
        <JobApplicantDetails
          personal={personal}
          setDetails={setDetails}
        />
      )}
      <div className="p-3 bg-white rounded-[1.25rem] shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] widthFix h-full max-h-[21rem] flex flex-col gap-2">
        <div className='w-full flex justify-between items-center'>
          <h2 className="text-lg font-bold text-[#031B59]">{t("newApplication")}</h2>
          <span className='p-2 bg-[#F2F6FA] rounded-[10px] cursor-pointer' onClick={handleNavigate}>{t('view_all')}</span>
        </div>
        <hr />
        <div className='h-full max-h-[20rem] overflow-y-auto custom_scroll flex flex-col gap-[0.2rem]'>
          {allNewApplicants?.length ?
            (allNewApplicants
              ?.map((candidate, index) => (
                <div
                  key={candidate.id || index}
                  className="flex justify-between items-center py-2">
                  <div className='flex items-center gap-1'>
                    <ProfileAvtar
                      height="3rem"
                      width="3rem"
                      name={candidate?.jobFullName}
                    />
                    <p>{candidate?.jobFullName}</p>
                  </div>
                  <div className='relative'>
                    <span
                      className='text-[1.5rem] cursor-pointer'
                      onClick={() => toggleDropdown(index)}
                    >
                      <BiDotsHorizontalRounded />
                    </span>
                    {dropdownOpen === index && (
                        <div
                        ref={menuRef}
                        className={`absolute right-0 mt-2 bg-white border border-gray-300 rounded  w-32 z-10 p-1 shadow- lg:[0px_4px_8px_0px_rgba(0,0,0,0.03)] ${
                          index >= 3 ? "bottom-[10px]" : ""
                        }`}
                      >
                        <div className='flex flex-col'>
                          <button
                            className='p-2 hover:bg-gray-100 text-left'
                            onClick={() => handleViewResume(candidate)}
                          >
                            {t("viewResume")}
                          </button>
                          <button
                            className='p-2 hover:bg-gray-100 text-left'
                            onClick={() => handleViewDetails(candidate)}
                          >
                            {t("viewDetail")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))) :
            null
          }
        </div>
      </div>
    </>
  );
}
export default DashboardNewApplication;