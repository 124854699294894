/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from "react";

import { Routes, Route } from "react-router";
import { BrowserRouter, Navigate } from "react-router-dom";
import Layout from "../component/layouts/Layout";
import AuthGuard from "../component/authGuard/AuthGuard.js";
import Login from "pages/auth/Login";
import JobOpeningDetails from "component/jobOpening/JobOpeningDetails";
import CurrentOpening from "component/jobOpening/CurrentOpening";
import JobApplicants from "component/jobOpening/JobApplicants";
import Page404 from "pages/Page404";
import DashboardPage from "pages/DashboardPage";
import MyCalendar from "component/dashboard/Calendar";
import CalendarHeader from "component/calender/CalenderHeader";
import FeedbackOutsider from "component/jobOpening/FeedbackOutsider";

export const MainRoutes = () => {
  const userData = JSON.parse(localStorage?.getItem("userLoginToken"));
  const unprotectedRoutes2 = [
    {
      path: "/login",
      component: Login,
      name: "Login",
    },
    {
      path: "/submit_feedback/:id",
      component: FeedbackOutsider,
      name: "Feedback",
    },
    //   {
    //     path: "/checkYourMail",
    //     component: CheckMail,
    //     name: "CheckYourMail",
    //   },
    //   {
    //     path: "/otp",
    //     component: OtpVerification,
    //     name: "Otp",
    //   },
    //   {
    //     path: "/reset/:token",
    //     component: ResetPassword,
    //     name: "ResetPassword",
    //   },
    {
      path: "404",
      component: Page404,
      name: "404Page",
    },
    {
      path: "*",
      component: Page404,
      name: "Page404",
    },

    { path: "/calender", component: CalendarHeader, name: "CalenderHeader" },
  ];

  const protectedRoutes = [
    //   {
    //     path: "/chat/group",
    //     component: { employee: GroupList, client: Page404 },
    //     name: "GroupList",
    //     role: ["employee", "admin", "client"],
    //   },
    //   {
    //     path: "/chat",
    //     component: { employee: Chat, client: Chat, admin: Chat },
    //     name: "Chat",
    //     role: ["employee", "client", "admin"],
    //   },
    {
      path: "/login",
      component: Login,
      name: "Login",
    },
    {
      path: "/opening/dashboard",
      name: "Dashboard",
      component: DashboardPage,
      role: null,
    },
    //   {
    //     path: "/timeSheet",
    //     component: TimeSheet,
    //     name: "Status",
    //     role: null,
    //   },
    //   {
    //     path: "/timeSheet/view/:id",
    //     component: ShowTimeSheet,
    //     name: "view-status",
    //     role: null,
    //   },
    //   {
    //     path: "/leave",
    //     component: LeavePage,
    //     name: "leave",
    //     role: null,
    //   },
    //   {
    //     path: "/leave/view/:id",
    //     component: { admin: LeaveDetails },
    //     name: "view-leave",
    //     role: ["admin"],
    //   },

    //   {
    //     path: "/attendance",
    //     component: { employee: Attendance, client: Page404 },
    //     name: "Attendance",
    //     role: ["employee", "client"],
    //   },
    //   {
    //     path: "/team",
    //     component: { employee: Team, client: Page404 },
    //     name: "Team",
    //     role: ["employee", "client"],
    //   },
    //   {
    //     path: "/interview",
    //     component: { employee: Interview, client: Page404 },
    //     name: "Interview",
    //     role: ["employee", "client", "admin"],
    //   },
    //   {
    //     path: "/myTeams",
    //     component: { admin: MyTeamsPage, client: Page404 },
    //     name: "My Teams",
    //     role: ["admin"],
    //   },
    //   {
    //     path: "/leave/apply",
    //     component: { employee: ApplyLeave },
    //     name: "ApplyLeave",
    //     role: ["employee"],
    //   },
    //   {
    //     path: "/leave/apply/:id",
    //     component: { employee: ApplyLeave },
    //     name: "ApplyLeave",
    //     role: ["employee"],
    //   },
    //   {
    //     path: "/timeSheet/apply",
    //     component: TimeSheetForm,
    //     name: "ApplyStatus",
    //     role: null,
    //   },
    //   {
    //     path: "/email",
    //     component: { employee: Mail, client: Mail, admin: Mail },
    //     name: "Email",
    //     role: ["employee", "client", "admin"],
    //   },
    //   {
    //     path: "/companyProfile",
    //     component: { admin: CompanyProfile },
    //     name: "CompanyProfile ",
    //     role: ["admin"],
    //   },
    //   {
    //     path: "/settings",
    //     component: { admin: Settings },
    //     name: "Settings",
    //     role: ["admin"],
    //   },
    //   {
    //     path: "/settings/manage",
    //     component: { admin: Manage },
    //     name: "Settings",
    //     role: ["admin"],
    //   },
    //   {
    //     path: "/employees/profilebasics/:id",
    //     name: "EmployeesProfile",
    //     component: ProfileBasics,
    //     role: null,
    //     // role: ["admin"],
    //   },
    //   {
    //     path: "/profileBasics",
    //     component: { employee: ProfileBasics },
    //     name: "Profile Basics",
    //     role: ["employee", "admin"],
    //   },
    //   {
    //     path: "/profileBasics/otherInfo",
    //     component: { employee: OtherInformation },
    //     name: "Other Information",
    //     role: ["employee"],
    //   },
    //   {
    //     path: "/profileBasics/changePassword",
    //     component: { employee: ChangePassword },
    //     name: "Change Password",
    //     role: ["employee"],
    //   },
    //   {
    //     path: "/profileBasics/salaryInfo",
    //     component: { employee: SalaryInformation },
    //     name: "Salary Information",
    //     role: ["employee"],
    //   },
    //   {
    //     path: "/profileBasics/Documents",
    //     component: { employee: Documents },
    //     name: "Documents",
    //     role: ["employee"],
    //   },
    //   {
    //     path: "profileBasics/bankDetails",
    //     name: "Bank Details",
    //     component: { employee: BankDetails },
    //     role: ["employee"],
    //   },
    //   {
    //     path: "/management",
    //     name: "Management",
    //     component: Management,
    //     role: null,
    //   },
    //   {
    //     path: "/designations",
    //     name: "Designation",
    //     component: DesignationListing,
    //     role: null,
    //   },
    {
      path: "/opening/currentOpening",
      name: "Job Opening",
      component: CurrentOpening,
      role: null,
    },
    {
      path: "/opening/currentOpening/:id",
      name: "Job Opening",
      component: JobOpeningDetails,
      role: null,
    },
    {
      path: "/opening/applicants",
      name: "Job Opening",
      component: JobApplicants,
      role: null,
    },
    // {
    //   path: "/calender",
    //   name: "Calendar",
    //   component: MyCalendar,
    //   role: null,
    // },

    {
      path: "opening/calendar",
      name: "Job Opening",
      component: MyCalendar,
      role: null,
    },
    // {
    //   path: "/jobApplications",
    //   name: "Job Opening",
    //   component: JobApplications,
    //   role: null,
    // },
    // {
    //   path: "/jobOpening/:id",
    //   name: "Job Opening Details",
    //   component: JobOpeningDetails,
    //   role: null,
    // },
    // {
    //   path: "/clients/Name/:id",
    //   name: "ClientInfo",
    //   component: ClientName,
    //   role: null,
    // },
    // {
    //   path: "/mail",
    //   component: { employee: Mail, client: Mail, admin: Mail },
    //   name: "Mail",
    //   role: ["employee", "client", "admin"],
    // },
    // {
    //   path: "/companyProfile",
    //   component: { admin: CompanyProfile },
    //   name: "Company Profile ",
    //   role: ["admin"],
    // },
    // {
    //   path: "/settings",
    //   component: { admin: Settings },
    //   name: "Settings",
    //   role: ["admin"],
    // },
    // {
    //   path: "/settings/manage",
    //   component: { admin: Manage },
    //   name: "Settings",
    //   role: ["admin"],
    // },
    // {
    //   path: "/profileBasics",
    //   component: { employee: ProfileBasics },
    //   name: "Profile Basics",
    //   role: ["employee"],
    // },
    // {
    //   path: "/profileBasics/personalInfo",
    //   component: { employee: PersonalInformation },
    //   name: "Personal Information",
    //   role: ["employee"],
    // },
    // {
    //   path: "/profileBasics/otherInfo",
    //   component: { employee: OtherInformation },
    //   name: "Other Information",
    //   role: ["employee"],
    // },
    // {
    //   path: "/profileBasics/changePassword",
    //   component: { employee: ChangePassword },
    //   name: "Change Password",
    //   role: ["employee"],
    // },
    // {
    //   path: "/profileBasics/salaryInfo",
    //   component: { employee: SalaryInformation },
    //   name: "Salary Information",
    //   role: ["employee"],
    // },
    // {
    //   path: "/profileBasics/Documents",
    //   component: { employee: Documents },
    //   name: "Documents",
    //   role: ["employee"],
    // },
    // {
    //   path: "profileBasics/bankDetails",
    //   name: "Bank Details",
    //   component: { employee: BankDetails },
    //   role: ["employee"],
    // },
    // {
    //   path: "/employees",
    //   name: "Employees",
    //   component: Employee,
    //   role: null,
    // },
    // {
    //   path: "/clients",
    //   name: "Clients",
    //   component: ClientListing,
    //   role: null,
    // },
    // {
    //   path: "/salary",
    //   name: "Salary",
    //   component: SalaryPage,
    //   role: null,
    // },
    // {
    //   path: "/salary/salaryDetails/:id",
    //   name: "Salary Details",
    //   component: SalaryDetails,
    //   role: null,
    // },
    // {
    //   path: "/projects",
    //   name: "Projects",
    //   component: ProjectsPage,
    //   role: null,
    // },
    // {
    //   path: "/management",
    //   name: "Management",
    //   component: Management,
    //   role: null,
    // },
    // {
    //   path: "/invoices",
    //   name: "Invoices",
    //   component: InvoicePage,
    //   role: null,
    // },
    // {
    //   path: "/EmployeeCSVfile",
    //   name: "EmployeeCSVfile",
    //   component: EmployeCSVfile,
    //   role: null,
    // },
    // {
    //   path: "/verification",
    //   name: "Verfication",
    //   component: { admin: Verfication },
    //   role: ["admin"],
    // },
    // {
    //   path: "/version",
    //   name: "Version",
    //   component: { admin: Version },
    //   role: ["admin"],
    // },
    // {
    //   path: "/access",
    //   name: "access",
    //   component: { admin: AccessControl },
    //   role: ["admin"],
    // },
    // {
    //   path: "/access/manageaccess/:name",
    //   name: "manageaccess",
    //   component: { admin: ManageAccess },
    //   role: ["admin"],
    // },
    // {
    //   path: "/VerficaticationDetails",
    //   name: "VerificationDetails",
    //   component: { admin: VerficationDetails },
    //   role: ["admin"],
    // },
  ];

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Navigate
              to={
                userData && userData?.token !== ""
                  ? "/opening/dashboard"
                  : "/login"
              }
            />
          }
        />
        <Route>
          {unprotectedRoutes2.map((obj, index) => (
            <Route
              key={index}
              path={obj.path}
              name={obj.name}
              Component={obj.component}
            />
          ))}
        </Route>
        <Route element={<Layout />}>
          {protectedRoutes?.map((obj, index) => (
            <Route
              key={index}
              path={obj.path}
              name={obj.name}
              element={
                <AuthGuard
                  Component={obj?.component}
                  roleList={obj?.role}
                  name={obj?.name}
                />
              }
            />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
