import LoaderComp from 'component/loader/LoaderComp';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxCross1 } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivity } from 'reduxToolkit/slices/JobSlice';
import AppliedForActivity from 'svgComponents/AppliedForActivity';
import InprogressActivity from 'svgComponents/InprogressActivity';
import OnholdActivity from 'svgComponents/OnholdActivity';
import RejectedActivity from 'svgComponents/RejectedActivity';
import SelectedJActivity from 'svgComponents/SelectedActivity';

const ViewActivity = ({ setActivityDetails, job }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activityReduxState } = useSelector((state) => state.jobs);
  const [loder, setLoder] = useState(false);
  const nowDate = new Date().toISOString().split('T')[0]

  useEffect(() => {
    dispatch(fetchActivity(job?.id, setLoder))
  }, [])

  return (
    <div
      className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-30 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="min-w-[40%] max-w-[40%] h-full bg-white flex-flex-col space-y-8
  shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
      >
        <div>
          <div className='flex justify-between p-[20px] items-center'>
            <h1 className='text-[20px] font-extrabold w-full capitalize'>{t('activityFor')} {job?.jobFullName}</h1>
            <div onClick={() => setActivityDetails(false)} className='border-2 p-2'>
              <RxCross1 />
            </div>
          </div>
          <hr />
          {
            loder ? (
              <div className='mt-4'>
                <LoaderComp />
              </div>
            ) : (
              <div className='h-[93vh] overflow-y-auto custom_scroll'>
                <div className='p-[25px]'>
                  {activityReduxState?.length > 0 ? (
                    <div className='flex flex-col gap-5'>
                      {activityReduxState?.map((newData, index) => (
                        <div>
                          <div className='font-bold text-[18px] pb-[20px] flex gap-2'>{newData?.date}
                            {
                              nowDate.includes(newData?.date) ?
                                <span>{t('today')}</span> : null
                            }
                          </div>
                          <div className='flex flex-col'>
                            {newData?.activities?.map((item) => {
                              return (
                                <>
                                  <div className='h-[2.7rem] w-[30px] border-r-2'></div>
                                  <div className='flex items-center w-full gap-10'>
                                    <div className='font-bold text-[15px] py-2'>{item?.time}</div>
                                    <div className='w-full'>
                                      <h1 className='h-[66px] items-center flex border w-full rounded-[5px] px-2 gap-3 capitalize'>
                                        {(item?.description?.includes('inprogress') || item?.description?.includes('interview')) ? (
                                          <div className='h-[2rem] w-[2rem] border rounded-full flex items-center justify-center bg-[#f2f6ff]'>
                                            <InprogressActivity />
                                          </div>
                                        ) : (
                                          <>
                                            {item?.description?.includes('hold') ? (
                                              <div className='h-[2rem] w-[2rem] border rounded-full flex items-center justify-center bg-[#f2f6ff]'>
                                                <OnholdActivity />
                                              </div>
                                            ) : (
                                              <>
                                                {item?.description?.includes('select') ? (
                                                  <div className='h-[2rem] w-[2rem] border rounded-full flex items-center justify-center bg-[#f2f6ff]'>
                                                    <SelectedJActivity />
                                                  </div>
                                                ) : (
                                                  <>
                                                    {item?.description?.includes('reject') ? (
                                                      <div className='h-[2rem] w-[2rem] border rounded-full flex items-center justify-center bg-[#f2f6ff]'>
                                                        <RejectedActivity />
                                                      </div>
                                                    ) : (
                                                      <>
                                                        {item?.description?.toLowerCase().includes('applied') ? (
                                                          <div className='h-[2rem] w-[2rem] border rounded-full flex items-center justify-center bg-[#f2f6ff]'>
                                                            <AppliedForActivity />
                                                          </div>
                                                        ) : null
                                                        }
                                                      </>
                                                    )
                                                    }
                                                  </>
                                                )
                                                }
                                              </>
                                            )}
                                          </>
                                        )}
                                        {item?.description} {'By'} {item?.createdBy ? item?.createdBy : "User"}</h1>
                                    </div>
                                  </div >
                                </>
                              )
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      className="w-full flex items-center
           justify-center font-medium"
                    >
                      {t("no_data_found")}
                    </div>
                  )


                  }
                </div>
              </div>
            )
          }

        </div>
      </div>
    </div >

  )
}

export default ViewActivity