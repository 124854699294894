import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { awsURL } from "utils/Constants";
import StarRating from "./StarRatings";
import { GoSmiley } from "react-icons/go";
import { MdOutlineAttachFile } from "react-icons/md";
import LoaderComp from "component/loader/LoaderComp";
import ProfileAvtar from "component/common/ProfileAvtar";
import EmojiPicker from "emoji-picker-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobApplicantFeedback,
  postOutsideFeedback,
} from "reduxToolkit/slices/JobSlice";
import ShimmerUI from "component/common/ShimmerUI";

const FeedbackOutsider = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getFeedbackApplicantData } = useSelector((state) => state.jobs);

  const [state, setState] = useState({
    rating: {
      "Technical Skills": 0,
      "Soft Skills": 0,
      "Experience and Knowledge": 0,
      "Problem-Solving and Critical Thinking": 0,
      "Project Management": 0,
      "Cultural Fit": 0,
      "Performance Under Pressure": 0,
      "Leadership and Initiative": 0,
    },
    showPicker: false,
    files: [],
    loader: false,
    description: "",
  });

  useEffect(() => {
    dispatch(getJobApplicantFeedback(id));
  }, []);

  const handleRating = (index, skill) => {
    setState((prevState) => ({
      ...prevState,
      rating: { ...prevState.rating, [skill]: index },
    }));
  };

  const handleChange = (e) => {
    setState((prevState) => ({ ...prevState, description: e.target.value }));
  };

  const onEmojiClick = (emojiData) => {
    setState((prevState) => ({
      ...prevState,
      description: prevState.description + emojiData.emoji,
      showPicker: false,
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setState((prevState) => ({ ...prevState, files }));
  };

  const handleIconClick = () => {
    document.getElementById("fileInputId").click();
  };

  const handleRemoveFile = (item) => {
    setState((prevState) => ({
      ...prevState,
      files: prevState.files.filter((file) => file.name !== item.name),
    }));
  };

  const handleCancel = () => {
    setState({
      rating: {
        "Technical Skills": 0,
        "Soft Skills": 0,
        "Experience and Knowledge": 0,
        "Problem-Solving and Critical Thinking": 0,
        "Project Management": 0,
        "Cultural Fit": 0,
        "Performance Under Pressure": 0,
        "Leadership and Initiative": 0,
      },
      showPicker: false,
      files: [],
      loader: false,
      description: "",
    });
  };

  const handleSubmitFeedback = async () => {
    const formData = new FormData();
    Object.keys(state.rating).forEach((skill) => {
      formData.append(
        `job_application_feedbacks[${skill.toLowerCase().replace(/ /g, "_")}]`,
        state.rating[skill]
      );
    });
    formData.append("description", state.description);
    state.files.forEach((file, index) => {
      formData.append(`job_application_feedbacks[images][${index}]`, file);
    });

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    await dispatch(postOutsideFeedback(formData, token, setState, state));
    await setTimeout(() => {
      navigate(-1);
    }, 2000);
  };

  return (
    <div className="min-h-screen bg-[#f0f6ff] w-full p-8">
      <div className="flex justify-center">
        <img
          className="w-[12rem] h-[4rem]"
          src={`${awsURL}/images/deeporion_logo.jpg`}
          alt="Company_logo"
        />
      </div>
      <div className="w-full flex justify-center ">
        <div className="border-2 rounded-xl border-dashed border-gray-400 p-8 m-5">
          <div className="w-[50vw] bg-white rounded-xl">
            <div>
              <div className="py-2 px-5">
                <h3 className="text-[1.4rem] font-bold">
                  {t("interviewFeed")}
                </h3>
              </div>
              <div className="bg-[#f0f6ff] flex justify-between items-center px-5 py-1">
                <div className="flex gap-2 items-center">
                  <ProfileAvtar
                    height="3rem"
                    width="3rem"
                    name={getFeedbackApplicantData?.jobFullName}
                    customClass="text-[1.1rem]"
                  />
                  <div>
                    <h3 className="text-[1.3rem] font-bold text-[#031b59] capitalize">
                      {getFeedbackApplicantData?.jobFullName || (
                        <ShimmerUI height="1.5rem" width="8rem" />
                      )}
                    </h3>
                    <p className="text-[#686868] mt-1">
                      {getFeedbackApplicantData?.email || (
                        <ShimmerUI height="0.8rem" width="12rem" />
                      )}
                    </p>
                  </div>
                </div>
                <div>{getFeedbackApplicantData?.round}</div>
              </div>
              <div className="relative w-full h-full p-5 flex flex-col justify-between">
                <div>
                  <h3 className="font-Roboto text-[1.2rem] font-bold mb-4">
                    {t("skillEvol")}
                  </h3>
                  <div className="font-Roboto text-lg text-[#686868] font-extrabold grid lg:grid-cols-2 gap-4">
                    {Object.keys(state?.rating)?.map((skill, index) => (
                      <div key={index} className="flex flex-col gap-2">
                        <p className="font-Roboto text-lg text-[#686868] font-[400]">
                          {t(skill)}
                        </p>
                        <StarRating
                          handleRating={handleRating}
                          rating={state.rating[skill]}
                          totalStars={5}
                          skill={skill}
                        />
                      </div>
                    ))}
                  </div>
                  <p className="font-Roboto text-md font-semibold text-[#A1A1A1] mt-5">
                    {t("writeAddition")}
                  </p>
                  <textarea
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md resize-none bg-[#F2F6FF]"
                    rows="4"
                    value={state.description}
                  ></textarea>
                  <div className="w-full flex justify-between items-center gap-6">
                    <div className="flex gap-[10px]">
                      {state.files?.map((item) => (
                        <div
                          className="w-full flex gap-3 items-center"
                          key={item.name}
                        >
                          <p className="h-fit overflow-hidden">{item.name}</p>
                          <button
                            onClick={() => handleRemoveFile(item)}
                            className="text-black"
                          >
                            &#x2716;
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="flex gap-3 relative">
                      {state.showPicker && (
                        <div className="absolute z-50 bottom-6 right-10">
                          <EmojiPicker onEmojiClick={onEmojiClick} />
                        </div>
                      )}
                      <button className="h-fit w-fit">
                        <GoSmiley
                          data-testid="ShowPicker-btn"
                          className="fill-[#A1A1A1] h-6 w-6"
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              showPicker: !prevState.showPicker,
                            }))
                          }
                        />
                      </button>
                      <button className="h-fit w-fit bg-[#F2F6FF] rounded-full">
                        <input
                          type="file"
                          id="fileInputId"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          multiple
                          accept="image/*"
                        />
                        <MdOutlineAttachFile
                          className="-rotate-45 h-6 w-6 fill-[#A1A1A1]"
                          data-testid="attach-file-icon"
                          onClick={handleIconClick}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="border-t border-gray-300 mt-4 w-full" />
                  <div className="flex justify-end items-center gap-4 mt-4">
                    <button
                      className="font-Roboto border px-5 py-3 text-[#686868] font-extrabold text-lg rounded-[3rem]"
                      onClick={handleCancel}
                    >
                      {t("cancel")}
                    </button>
                    {state.loader ? (
                      <LoaderComp />
                    ) : (
                      <button
                        className={`px-8 py-3 ${
                          Object.values(state.rating).every(
                            (value) => value === 0
                          )
                            ? "bg-gray-400"
                            : "bg-[#031B59]"
                        } text-white text-lg rounded-[3rem] flex items-center`}
                        disabled={Object.values(state.rating).every(
                          (value) => value === 0
                        )}
                        onClick={handleSubmitFeedback}
                      >
                        {t("save")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackOutsider;
