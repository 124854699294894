import * as Yup from "yup";

export const jobOpeningValidation = Yup.object().shape({
  title: Yup.string()
    .required("Please enter job title")
    .max(50, "Job Name cannot be greater than 50 words"),
  description: Yup.string().required("Please enter description "),
  requirements: Yup.string().required("Please enter experience "),
  vacancyCount: Yup.string()
    .required("Please enter vacancy no")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(1, "Must be exactly 1 digits"),
  jobType: Yup.string().required("Please enter job type "),
  status: Yup.string().required("Please select status "),
  location: Yup.array()
    .of(Yup.string())
    .required("Location is Required")
    .min(1, "Min one location is required"),
});

export const offerLetterValidation = Yup.object().shape({
  letterType: Yup.string().required("Please enter Letter Type "),
  ctc: Yup.string().required("Please enter CTC "),
  deductions: Yup.string().required("Please enter job deduction "),
  onBoardingDate: Yup.string().required("Please select Onboarding date"),
  preLocation: Yup.string().required("Please select location "),
  timeEmployment: Yup.string().required("Please type something !"),
  description: Yup.string()
  .required("Description is required")
  .min(10, "Description must be at least 10 characters long"),
  timeEmployment: Yup.string().required("Time of employment is required"),
  note: Yup.string(),
  termsCondition: Yup.string(),
});
