import DatePicker from 'component/common/DatePicker';
import Popup from 'component/common/Popup'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import { onBoardinDateApi } from 'reduxToolkit/slices/JobSlice';
import { getDateDashFormat } from 'utils/date';
import * as Yup from "yup";

const OnBoardDate = ({ setOnBoardPop, jobDetails,setAgainData,editDate }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [newLoder, setNewLoder] = useState(false);
  const initialValues = {
    onDate: editDate?.onBoardDate ? editDate?.onBoardDate : ""
  }

  const DateValidation = Yup.object({
    onDate: Yup.date()
      .required("Date is required")
      .typeError("Invalid date format"),
  });

  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: DateValidation,
    onSubmit: async (values) => {
      const jsonObj = {
        onboarding_date: values?.onDate
      }
      dispatch(onBoardinDateApi(jobDetails?.id, jsonObj, setOnBoardPop,setNewLoder,setAgainData))
    },
  });

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } = Formik;

  const handleCloseOnBoardPopUp = () => {
    setOnBoardPop(false);
  }

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <div>
      <Popup
        title={ editDate ? t('editOnBoardDate') : t('setOnBoardDate')}
        popupBox={handleCloseOnBoardPopUp}
        submitBtnText={editDate ? t("update") : t("Set")}
        handleSubmit={handleSubmit}
        submitOnce={newLoder}
      >
        <div className='p-3'>
          <h1>{t('onBoardingDate')}</h1>
          <DatePicker
            name="onDate"
            id="onDate"
            value={values?.onDate}
            handleChange={handleChange}
            handleBlur={handleBlur}
            min={getTodayDate()}
            styles={"h-[2.625rem] w-full"}
          />
          {errors.onDate && touched.onDate ? (
            <p className="text-[red]">{errors.onDate}</p>
          ) : null}
        </div>
      </Popup>
    </div>
  )
}

export default OnBoardDate