import { ClickOutsideClose } from 'component/common/ClickOutsideClose';
import ProfileAvtar from 'component/common/ProfileAvtar';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardOnboard } from 'reduxToolkit/slices/JobSlice';
import AddEmployeeDash from './AddEmployeeDash';
import ViewAllPopUp from './ViewAllPopUp';

function DashboardOnboard() {
  const [addEmpPop, setAddEmpPop] = useState(null);
  const [empDetal, setEmpDetail] = useState(null);
  const [openAddEmp, setOpenAddEmp] = useState(false);
  const [openViewAllPopUP, setOpenViewAllPopUp] = useState(false);
  const [isClickedForPop, setIsClickedForPop] = useState(false);
  const { t } = useTranslation();
  const menuRef = useRef();
  const dispatch = useDispatch();
  const { allDashboardOnboard } = useSelector((state) => state.jobs);
  const localData = JSON.parse(localStorage.getItem('userLoginToken')) || {}; 
  const job = allDashboardOnboard?.data?.map(item => item);
  const { clickNewData } = useSelector((state) => state.jobs);


  useEffect(() => {
    if (localData?.token) {
    dispatch(dashboardOnboard("",clickNewData,job));
    }
  }, [clickNewData])

  const handleAddEmployee = (index) => {
    if (isClickedForPop) {
      setAddEmpPop(index === addEmpPop ? null : index);
      setIsClickedForPop(false);
    }
  }

  const handleOpenAddEmployeePop = (data) => {
    setEmpDetail(data);
    setOpenAddEmp(true);
  }

  const handleOpenViewAll = (data) => {
    setOpenViewAllPopUp(true);
  }

  ClickOutsideClose(addEmpPop, setIsClickedForPop, menuRef, () => setAddEmpPop(null))

  return (
    <>
      {openAddEmp && <AddEmployeeDash setOpenAddEmp={setOpenAddEmp} empDetal={empDetal} />}
      {openViewAllPopUP && <ViewAllPopUp setOpenViewAllPopUp={setOpenViewAllPopUp} />}
      <div className="p-3 bg-white rounded-[1.25rem] shadow-[0px_0px_20px_0px_rgba(3,27,89,0.10)] widthFix h-full max-h-[21rem] flex flex-col gap-2">
        <div className='w-full flex justify-between items-center'>
          <h2 className="text-lg font-bold text-[#031b59]">{t("onboardCandidate")}</h2>
          <span className='p-2 bg-[#f2f6fa] rounded-[10px] cursor-pointer' onClick={handleOpenViewAll}>{t('view_all')}</span>
        </div>
        <hr />
        <div className='h-auto flex flex-col gap-[0.2rem]'>
          {allDashboardOnboard?.data?.length > 0 ? (allDashboardOnboard?.data?.slice(0, 4)?.map((candidate, index) => (
            <>
              <div key={index} className="flex justify-between items-center py-2">
                <div className='flex  items-center gap-1'>
                  <ProfileAvtar
                    height="3rem"
                    width="3rem"
                    name={candidate?.jobFullName}
                  />
                  <p>{candidate?.jobFullName}</p>
                </div>
                <div className='relative'>
                  <span className='text-[1.5rem] cursor-pointer'
                    onClick={() => handleAddEmployee(index)}
                  >
                    <BiDotsHorizontalRounded />
                  </span>
                  {addEmpPop === index && (
                    <div className='absolute cursor-pointer right-4 top-3 w-[9.1rem] bg-white shadow-[0_0px_20px_0px_rgba(4,27,89,0.10)] rounded-lg p-[0.5rem] border' ref={menuRef}
                      onClick={() => handleOpenAddEmployeePop(candidate)}
                    >
                      <p>{t('addEmployeeHere')}</p>
                    </div>
                  )}
                </div>
              </div>
            </>
          ))) : (
            <div className='flex justify-center'>
              <h1 className='text-[1rem] capitalize'>
                {t("No Candidate Onboard for Today")}
              </h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardOnboard;
