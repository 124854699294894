import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { RxCross1 } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import LoaderComp from "component/loader/LoaderComp";
import { updateDropdownStatus } from "reduxToolkit/slices/JobSlice";

export const ActionButton = ({ onClick, text }) => {
  return (
    <button
      className="flex h-[3rem] p-[1rem_1.875rem] w-[6rem] justify-center items-center gap-[0.5rem]
                 rounded-[2.5rem] bg-red-500 font-inter text-[1rem] font-bold leading-normal text-white"
      type="submit"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const RejectPopUp = ({
  statusString,
  rejectDetails,
  setRejectPop,
  setAgainData,
  setDetails,
}) => {
  const [text, setText] = useState("");
  const [loder, setLoder] = useState(false);
  const newText = rejectDetails?.fullName;
  const fullNewName = newText?.split(" ");
  const location = useLocation();
  const path = location.pathname.includes("currentOpening");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoder(true);
    const jsonObj = {
      status: statusString,
      reason: text,
      job_application_ids: rejectDetails?.id,
    };
    await dispatch(updateDropdownStatus(jsonObj, setAgainData, setRejectPop));
    setLoder(false);
  };

  return (
    <div
      className="min-w-[40%] h-full p-5 bg-white  space-y-8
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight"
    >
      <div
        className="w-full h-full flex items-center
justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
      >
        <div
          className="min-w-[40%] h-full bg-white space-y-8
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight max-w-[40%] "
        >
          <div>
            <div className="flex justify-between items-center border-b-2 p-5">
              <h1 className="text-[1.50rem] text-[#031B59] bold capitalize">
                {statusString} {t("rejectDetails")}
              </h1>
              <div onClick={() => setRejectPop(false)} className="border-2 p-2">
                <RxCross1 />
              </div>
            </div>
            <div className="h-[90vh]">
              <div className="p-7 flex flex-col gap-8">
                <div className="gap-7 mt-1">
                  <div className="flex gap-[2rem]">
                    <div className="w-[21.3rem]">
                      <label htmlFor="firstName" className="text-[12px] normal">
                        {t("firstName")}
                      </label>
                      <p className="text-[1rem] capitalize">
                        {path ? fullNewName[0] : rejectDetails?.firstName}
                      </p>
                    </div>
                    <div className="w-[17.3rem]">
                      <label htmlFor="lastName" className="text-[12px] normal">
                        {t("lastName")}
                      </label>
                      <p className="text-[1rem] capitalize">
                        {path ? fullNewName[1] : rejectDetails?.lastName}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-[2rem]">
                    <div className="w-[21.3rem]">
                      <label htmlFor="email" className="text-[12px] normal">
                        {t("email")}
                      </label>
                      <p className="text-[1rem] ">{rejectDetails?.email}</p>
                    </div>
                    <div className="w-[17.3rem]">
                      <label htmlFor="contact" className="text-[12px] normal">
                        {t("contact")}
                      </label>
                      <p className="text-[1rem] ">{rejectDetails?.contactNo}</p>
                    </div>
                  </div>
                  <div className="flex gap-[2rem]">
                    <div className="w-[21.3rem]">
                      <label htmlFor="country" className="text-[12px] normal">
                        {t("country")}
                      </label>
                      <p className="text-[1rem] capitalize">
                        {rejectDetails?.country}
                      </p>
                    </div>
                    <div className="w-[17.3rem]">
                      <label htmlFor="state" className="text-[12px] normal">
                        {t("state")}
                      </label>
                      <p className="text-[1rem] capitalize">
                        {rejectDetails?.state}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-[2rem]">
                    <div className="w-[21.3rem]">
                      <label htmlFor="linkedIn" className="text-[12px] normal">
                        {t("linkedIn")}
                      </label>
                      <p className="w-auto ">{rejectDetails?.linkedIn}</p>
                    </div>
                    <div className="w-[17.3rem]">
                      <label htmlFor="Portfolio" className="text-[12px] normal">
                        {t("portfolio")}
                      </label>
                      <p className=" w-auto ">{rejectDetails?.portFolio}</p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="Reason for Rejection"
                      className="block mb-2 text-sm medium
                       text-gray-900 dark:text-white gap-16 font-semibold"
                    >
                      {t("optionalLang")}{" "}
                      {statusString === "onhold" ? t("onHold") : t("rejection")}{" "}
                      {"(Optional)"}
                    </label>
                    <textarea
                      id="message"
                      rows="8"
                      className="block p-2.5 w-full text-sm
                       text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500
                        focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600
                         dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                          dark:focus:border-blue-500"
                      placeholder="Write here..."
                      onChange={(e) => setText(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="w-full px-7 py-5 flex justify-end items-center gap-8">
                    {loder ? (
                      <LoaderComp />
                    ) : (
                      <ActionButton
                        onClick={handleSubmit}
                        text={
                          statusString === "onhold" ? t("onHold") : t("reject")
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectPopUp;

RejectPopUp.propTypes = {
  statusString: PropTypes.string,
  setRejectPop: PropTypes.any,
  setAgainData: PropTypes.any,
  rejectDetails: PropTypes.any,
  job: PropTypes.any,
  setDetails: PropTypes.func,
};
