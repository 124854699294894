import useDebounce from 'hooks/useDebounce';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getJobApplicantsData } from 'reduxToolkit/slices/JobSlice';

const UseFetchJobApplicantsData = ({
  query, currentPage, status, setLoading, againgetData, clickCheck, itemsPagination, apiCheckLocation, apiCheckStatus, apiCheckReference, apiCheckDate, apiCheckOnboardingDate
}) => {
  const dispatch = useDispatch();
  let searchQuery = useDebounce(query, 500);
  useEffect(() => {
    const fetchJobsData = () => {
      if (searchQuery) {
        dispatch(
          getJobApplicantsData(
            currentPage,
            status,
            setLoading,
            searchQuery,
            clickCheck,
            itemsPagination,
            apiCheckLocation,
            apiCheckStatus,
            apiCheckReference,
            apiCheckDate,
            apiCheckOnboardingDate
          )
        );
      } else {
        dispatch(
          getJobApplicantsData(
            currentPage,
            status,
            setLoading,
            searchQuery,
            clickCheck,
            itemsPagination,
            apiCheckLocation,
            apiCheckStatus,
            apiCheckReference,
            apiCheckDate,
            apiCheckOnboardingDate
          )
        );
      }
    };
    fetchJobsData();
  }, [currentPage, status, againgetData, searchQuery, clickCheck, itemsPagination]);
};

export default UseFetchJobApplicantsData;
