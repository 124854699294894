import React from "react";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const RightOpenPop = ({
  onClose,
  children,
  cancelText,
  saveText,
  handlePreview,
  previewIcon,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
      <div
        className="min-w-[40%] w-full max-w-md h-full bg-white flex flex-col
           space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all"
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="w-full p-5 bg-white flex justify-between border border-b">
              <div className="flex justify-center items-center font-extrabold">
                <h2 className="font-extrabold text-xl text-[#031B59]">
                  {t("generateLetter")}
                </h2>
              </div>
              <div
                className="border p-1 text-2xl cursor-pointer font-extrabold"
                onClick={onClose}
              >
                <RxCross2 />
              </div>
            </div>
            <div>{children}</div>
          </div>
          <div className="w-full h-fit p-5 bg-white flex justify-end overflow-y-hidden border border-t">
            <div className="flex items-center justify-center space-x-4">
              {previewIcon ? (
                <button
                  className="w-[7.625rem] border border-[#031b59] h-[2.688rem] flex items-center justify-center rounded-full text-[#031b59] gap-2 text-[1.1rem]"
                  onClick={handlePreview}
                >
                  {previewIcon && (
                    <MdOutlineRemoveRedEye className="text-[#031b59]" />
                  )}
                  {cancelText}
                </button>
              ) : (
                <button className="w-[7.625rem] border h-[2.688rem] flex items-center justify-center rounded-full text-[#686868] text-[1.1rem]">
                  {cancelText}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightOpenPop;
