import useDebounce from 'hooks/useDebounce';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getJobApplications } from 'reduxToolkit/slices/JobSlice';

const UseFetchJobSection = ({
          currentPage,
          id,status,callback,againgetData,query,newId,permissionToFetch,clickCheck,itemsPagination,apiCheckLocation,apiCheckStatus,apiCheckReference,apiCheckDate,apiCheckOnboardingDate
}) => {
  const dispatch = useDispatch();
  let searchQuery = useDebounce(query, 500);
  useEffect(() => {
    const fetchJobsData = () => {
      if (searchQuery) {
        dispatch(
          getJobApplications(
            id,
            status,
            callback,
            searchQuery,
            newId,
            apiCheckLocation,
            apiCheckStatus,
            apiCheckReference,
            apiCheckDate,
            apiCheckOnboardingDate
          )
        );
      } else {
        dispatch(
          getJobApplications(
            id,
            status,
            callback,
            "",
            "",
            clickCheck,
            itemsPagination,
            currentPage,
            apiCheckLocation,
            apiCheckStatus,
            apiCheckReference,
            apiCheckDate,
            apiCheckOnboardingDate
          )
        );
      }
    };
    fetchJobsData();
  }, [id, status, againgetData, searchQuery, clickCheck, currentPage, itemsPagination]);
};

export default UseFetchJobSection;
